<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="名称:">
                    <el-input v-model="queryParams.name" placeholder="请输入名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                    <el-input v-model="queryParams.phone" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="已联系" :value="2"></el-option>
                        <el-option label="未联系" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- <div style="float: left">
                <el-button type="primary" @click="sync" v-if="isSync">同步微信h5模板</el-button>
                <el-button type="primary" v-else><i class="el-icon-loading"></i>同步中</el-button>
            </div> -->
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 50px;">
                <el-table-column label="id" prop="id" align="center" width="80"></el-table-column>
                <el-table-column label="姓名" prop="name" align="center" width="150"></el-table-column>
                <el-table-column label="电话" prop="phone" align="center" width="150"></el-table-column>
                <el-table-column label="邮箱" prop="email" align="center" width="220"></el-table-column>
                <el-table-column label="职位" prop="job_position" align="center" width="150"></el-table-column>
                <el-table-column label="公司" prop="company" align="center"></el-table-column>
                <el-table-column label="需求内容" prop="content" align="center"></el-table-column>
                <el-table-column label="备注" prop="remark" align="center"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">已联系</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">未联系</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="remove(scope.row.id)">删除</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">未联系
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" @click="state(scope.row, 2)">
                            联系</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="140px" :rules="rules" label-position="left">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入名称" disabled></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入手机号" disabled></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="form.email" placeholder="请输入邮箱" disabled></el-input>
                </el-form-item>
                <el-form-item label="消息内容" prop="content">
                    <el-input type="textarea" autosize placeholder="请输入消息内容" v-model="form.content" disabled>
                    </el-input>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" autosize placeholder="请输入备注" v-model="form.remark">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            isSync: true,
            message: [],
            rules: {
                // name: [{
                //     required: true,
                //     message: '链接名称不能为空',
                //     trigger: 'blur'
                // }],
                // path: [{
                //     required: true,
                //     message: '链接地址不能为空',
                //     trigger: 'blur'
                // }],
                // state: [{
                //     required: true,
                //     message: '状态不能为空',
                //     trigger: 'blur'
                // }],
            },
            menuList: ['消息', '官网消息'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            addVisible: false,
            title: '添加热词',
            form: {
            },
            title: '编辑'
        };
    },
    created() {
        this.loadData()
    },
    methods: {
        // getconf() {
        //     request.get('/common/conf').then(ret => {
        //         if (ret.code == 1) {
        //             this.message = ret.data.message
        //             console.log(22222222222, this.message)
        //         } else {
        //             this.$message.error(ret.msg);
        //         }
        //     });
        // },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否已联系?';
            } else {
                this.confirmContent = '确定是否未联系?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        cellstyle() {
            return 'text-align: center';
        },
        loadData() {
            request.get('/common/demand/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //同步
        // sync() {
        //     this.isSync = false
        //     request.get('/message/weixin/syn').then(ret => {
        //         if (ret.code == 1) {
        //             this.isSync = true
        //             this.loadData()
        //         } else {
        //             this.$message.error(ret.msg);
        //         }
        //     });
        // },
        //编辑
        edit(index, row) {
            this.form = {}
            this.current = row.id;
            const item = this.pageInfo.list[index];
            this.form = Object.assign({}, item);
            this.addVisible = true;

        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        var url = '/common/demand/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success('编辑成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/common/demand/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 删除
        remove(id) {
            request.post('/common/demand/remove', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('删除成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        }
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box >>> .el-textarea {
    width: 80%;
}
.dialog_box >>> .el-input--small .el-input__inner {
    width: 148%;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>
